<template>
  <div class="admin-list">
    <PageTitle title="區域設定" btn="新增" @btnClick="openDialog('create')" />
    <FiltersContainer>
      <el-input v-model="nameSearch" clearable placeholder="輸入名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table v-loading="loading" :data="shopList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column label="名稱" prop="name" align="center" />
        <el-table-column label="電話號碼" prop="phone" align="center" />
        <el-table-column label="聯絡地址" prop="address" align="center" />
        <el-table-column label="方案" align="center">
          <template slot-scope="scope">
            {{ shopContractName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              hideDelete
              @edit="openDialog('update'), (selectRow = scope.row), syncFormData()"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="shopsCount"
        @pageChange="changePage"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      v-if="showDialog"
      :visible="showDialog"
      :close-on-click-modal="false"
      :title="dialogType === 'create' ? '新增區域' : '編輯區域'"
      @close="resetForm(), showDialog = false"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" placeholder="請輸入名稱" />
        </el-form-item>

        <el-form-item label="電話號碼" prop="phone">
          <el-input v-model="formData.phone" placeholder="請輸入電話" />
        </el-form-item>

        <el-form-item label="聯絡地址" prop="address">
          <el-input v-model="formData.address" placeholder="請輸入聯絡地址" />
        </el-form-item>

        <el-form-item label="合約方案" prop="ContractPlanId">
          <ContractPlanSelect
            :mode="dialogType"
            :shopPlan="formData.ContractPlan"
            :model.sync="formData.ContractPlanId"
          />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
          plain
          @click=";(showDialog = false), resetForm()"
        >
          取消
        </el-button>
        <el-button v-loading="btnLoading" type="primary" @click="handleDialogConfirm">
          {{
            dialogType === "create" ? "新增" : "儲存"
          }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import SearchInput from "@/components/SearchInput"
import ContractPlanSelect from '@/components/Select/ContractPlanSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetShops, CreateShop, UpdateShop, GetShopsCount, GetShopById } from '@/api/shop'
import { noEmptyRules, phoneRules } from '@/validation'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'

import { get } from 'lodash'

export default {
  name: 'BranchShopList',
  components: { ContractPlanSelect, EmptyBlock },
  data: () => ({
    loading: false,
    btnLoading: false,
    userSearch: '',
    selectRow: null,
    nameSearch: '',

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    roleList: ['orgAdmin'],

    shopList: [],
    shopsCount: 0,

    // Dialog
    showDialog: false,
    dialogType: 'create',

    // create user from
    formData: {
      name: '',
      phone: '',
      address: '',
      ContractPlanId: '',
    },

    // from rules
    formRules: {
      name: [noEmptyRules()],
      phone: [noEmptyRules(), phoneRules(true, true, true)],
      address: [noEmptyRules()],
      ContractPlanId: [noEmptyRules()],
    },
  }),

  computed: {
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async refresh () {
      await this.getShops()
      await this.getShopsCount()
    },
    showInput (typeList) {
      return !!typeList.includes(this.dialogType)
    },

    async changePage () {
      await this.getShops()
    },
    shopContractName (row) {
      return get(row, 'ContractPlan.name', '-')
    },
    clearFormData () {
      this.formData = {
        name: '',
        phone: '',
        address: '',
        ContractPlanId: '',
      }
    },

    async syncFormData () {
      await this.findShop()
      this.formData = JSON.parse(JSON.stringify(this.selectRow))
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.clearFormData()
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async handleDialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      try {
        if (type === 'create') await this.createShop()
        if (type === 'update') await this.updateShop()
        this.showDialog = false
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findShop () {
      const [res, err] = await GetShopById(this.selectRow.id)
      if (err) return this.$message.error(err || err.msg)
      this.selectRow = res
    },

    //= > 取得店家
    async getShops () {
      this.loading = true
      try {
        this.shopList = await GetShops({
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得總店家數量
    async getShopsCount () {
      try {
        this.shopsCount = await GetShopsCount({
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 創建店家
    async createShop () {
      this.btnLoading = true
      const form = this.formData
      try {
        const shop = await CreateShop({
          name: form.name,
          phone: form.phone === '' ? undefined : form.phone,
          address: form.address === '' ? undefined : form.address,
          contractPlanId:
          form.ContractPlanId === 'none' ? undefined : form.ContractPlanId,
        })
        await this.getShops()
        await this.$store.dispatch('user/addAllowShop', shop)
        this.$message.success('新增成功!')
        this.btnLoading = false
      } catch (error) {
        this.btnLoading = false
        return this.$message.error(error)
      }
    },

    //= > 更新店家
    async updateShop () {
      this.btnLoading = true
      try {
        await UpdateShop({
          shopId: this.selectRow.id,
          name: this.formData.name,
          phone: this.formData.phone,
          address: this.formData.address,
          contractPlanId:
          this.formData.ContractPlanId === 'none'
            ? null
            : this.formData.ContractPlanId,
        })
        this.selectRow = null
        await this.getShops()
        this.$message.success('更新成功!')
        this.btnLoading = false
      } catch (error) {
        this.btnLoading = false
        return this.$message.error(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
